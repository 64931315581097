.menu-item {
  background-color: #fff;
  border-radius: 0;
  text-align: center;
  min-height: 48px;
  min-width: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0;
  padding: 6px;
  text-wrap: nowrap;
}

.menu-item.sub-menu-item {
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  cursor: grab;
}

.back-button {
  border-bottom: 1px solid var(--chakra-colors-gray-100);
}

.menu-item:hover {
  background-color: var(--chakra-colors-gray-50);
}

.drawer {
  position: absolute;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

#drawer {
  left: 24px;
}

#text-drawer,
#image-drawer,
#other-drawer {
  opacity: 0;
  left: 90px;
  visibility: hidden;
}
