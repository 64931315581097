.react-flow__node {
  border-radius: 4px;
  width: 240px;
}

.node {
  height: 100%;
  width: 100%;
  padding: 16px;
  color: #222;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background: #fff;

  /* base */
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.react-flow__node.selected {
  box-shadow:
    0px 4px 6px 2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    1px 1px 0px 0px #805ad5,
    -1px -1px 0px 0px #805ad5,
    1px -1px 0px 0px #805ad5,
    -1px 1px 0px 0px #805ad5;
}

.react-flow__handle {
  border: none;
}

.react-flow__handle.text {
  background: #3182ce;
  width: 8px;
  height: 8px;
}

.react-flow__handle-valid.text {
  transform-origin: 12px 4px;
  transform: scale(150%);
  animation: ease-in-out 200ms;
}

.react-flow__handle.image {
  background: #d53f8c;
  border-radius: 0;
  width: 8px;
  height: 8px;
}

.react-flow__handle-valid.image {
  transform-origin: 12px 6px;
  transform: scale(150%);
  animation: ease-in-out 200ms;
}

.react-flow__handle-top.image {
}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: #9747ff !important;
}

.react-flow__edge.text .react-flow__edge-path {
  stroke: #3182ce;
}

.react-flow__edge.image .react-flow__edge-path {
  stroke: #d53f8c;
}

.placeholder__text {
  color: #a0aec0;
}
