.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100vh;
}

h1 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  margin-top: 16px;
  margin-bottom: 4px;
}

h2 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  margin-top: 12px;
  margin-bottom: 4px;
}

video {
  display: block;
  border: 1px solid #ddd;
  height: 100%;
  width: 100%;
}
